.products-page {
  min-height: 100vh;
  border-top: 100px solid transparent;
  padding: var(--space-big);
}

.products {
  display: grid;
  place-items: center;
  grid-template-columns: repeat(1, 1fr);
  gap: 50px;
}

.products-page p {
  text-transform: uppercase;
  margin: 50px 0;
}

@media (min-width: 650px) {
  .products {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1000px) {
  .products {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 300px) and (max-width: 375px) {
  .products-page {
    padding: var(--space-medium);
  }
}
