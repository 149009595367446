.navbar {
  height: 100px;
  width: 100vw;
  position: absolute;
  top: 0;
  padding: 0 var(--space-medium);
  background-color: black;
  font-family: "Judson", serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar > * {
  text-decoration: none;
  font-size: var(--text-paragraph-mobile);
  color: white;
}

.navbar > *:hover {
  color: var(--color-purple);
}

.navbar .logo-holder {
  width: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.navbar .logo-holder:hover {
  color: white;
}

.navbar .logo {
  width: 100px;
}

.navbar span {
  font-family: "Josefin Slab", serif;
  font-size: var(--text-paragraph-x-small);
}

/*Phablets and Tablets*/

@media screen and (min-width: 300px) and (max-width: 364px) {
  .navbar > * {
    font-size: 10px;
  }
}

@media screen and (min-width: 1000px) {
  .navbar {
    padding: 0 50px;
  }
}
