.category-card {
  width: 100%;
  max-width: 315px;
  height: 300px;
  border-radius: 10px;
  padding: var(--space-medium);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: var(--color-purple);
}

.category-card img {
  width: 100%;
  height: 70%;
  background-color: white;
  border-radius: 10px;
  object-fit: cover;
}

.category-card:nth-child(1) img {
  object-position: right;
}

.category-card h1 {
  font-size: 20px;
  color: black;
  margin-bottom: 0;
}

.navigation-section {
  height: 20%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

@media (min-width: 300px) and (max-width: 375px) {
  .category-card h1 {
    font-size: 18px;
    margin-bottom: var(--space-small);
  }

  .category-card span {
    font-size: 12px;
  }
}
