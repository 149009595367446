.about-page {
  min-height: 100vh;
  padding: 10rem;
}

.about {
  margin-top: 2rem;
}

@media (min-width: 300px) and (max-width: 1000px) {
  .about-page {
    padding: 10rem 2rem;
  }
}
