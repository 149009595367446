.hero {
  border-top: 100px solid transparent;
}

.quote-link-section {
  height: 50px;
}

.hero button {
  color: red;
  font-family: "Markazi Text", serif;
  font-size: 25px;
  position: absolute;
  top: 110px;
  right: -20px;
  animation: move 40s infinite;
  animation-timing-function: linear;
  overflow: hidden;
}

@keyframes move {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateX(-100vw);
  }
}

.hero-image-section {
  padding: 0 var(--space-medium) var(--space-medium);
}

.hero-image {
  height: 65vh;
  max-width: 100%;
  width: 100vw;
  object-fit: cover;
  object-position: center;
}
