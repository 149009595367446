h1,
h2,
h3,
p {
  margin-top: 0;
}

h1 {
  font-size: 34px;
}

h2 {
  font-size: 20px;
}

h3 {
  font-size: 24px;
}

p {
  font-size: var(--text-paragraph);
}

@media screen and (min-width: 300px) and (max-width: 749px) {
  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 18px;
  }

  p {
    font-size: var(--text-paragraph-mobile);
  }
}

@media screen and (min-width: 750px) and (max-width: 1000px) {
  h1 {
    font-size: 26px;
  }

  p {
    font-size: var(--text-paragraph-tablet);
  }
}

@media screen and (min-width: 1001px) and (max-width: 1280px) {
  h1 {
    font-size: 30px;
  }
}
