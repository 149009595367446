/*Fonts*/
@import url("https://fonts.googleapis.com/css2?family=Josefin+Slab:wght@700&family=Judson:wght@700&family=Libre+Baskerville:wght@700&family=Limelight&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lalezar&family=Markazi+Text:wght@500;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  color: var(--color-grey);
  line-height: 200%;
  scroll-behavior: smooth;
  background-color: var(--color-white-pale);
}

button {
  background: none;
  border: none;
  cursor: pointer;
}
