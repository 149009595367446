.brand-card {
  height: 250px;
  width: 200px;
  border-radius: 5px;
  border: var(--color-purple) solid 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  flex-direction: column;
  background-color: white;
}

.motorola {
  height: 60px;
  width: 60px;
}

.horizon {
  height: 30px;
  width: 120px;
  margin-left: var(--space-medium);
}

.icom {
  height: 50px;
  width: 90px;
}

.iridium {
  height: 60px;
  width: 150px;
  margin-left: -10px;
}

.kenwood {
  height: 30px;
  width: 130px;
}

.thuraya {
  height: 90px;
  width: 130px;
}

.yaesu {
  height: 90px;
  width: 150px;
}

.brand-card button {
  height: 40px;
  padding: 0 var(--space-medium);
  background-color: var(--color-purple);
  border-radius: 5px;
}
