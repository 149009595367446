.slogan {
  padding: var(--space-big);
  width: 100vw;
  padding: var(--space-medium);
  background-color: #f1f1f1;
}

@media screen and (min-width: 300px) and (max-width: 364px) {
  .slogan p {
    font-size: 17px;
  }
}
