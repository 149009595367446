.footer {
  width: 100vw;
  height: 100px;
  background-color: black;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.copyright {
  font-size: 15px;
}
