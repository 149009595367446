.brands-section {
  width: 100vw;
  padding: var(--space-big);
  gap: var(--space-big);
}

.brands-section h2 {
  font-family: "Lalezar", cursive;
  margin-bottom: var(--space-big);
}

.brands {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  place-items: center;
  gap: var(--space-big);
}

@media (min-width: 500px) and (max-width: 700px) {
  .brands {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 701px) {
  .brands {
    grid-template-columns: repeat(3, 1fr);
  }
}
