.categories-page {
  border-top: 100px solid transparent;
  width: 100vw;
  min-height: 100vh;
  padding: var(--space-big);
  display: flex;
  flex-direction: column;
  gap: var(--space-big);
}

.categories-page h1 {
  text-transform: uppercase;
  text-align: center;
}

.categories-page p {
  text-align: center;
}

.categories-section {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  place-items: center;
  gap: var(--space-big);
  margin-top: var(--space-big);
}

.categories-page .coming-soon {
  width: 100vw;
  height: 100vh;
}

@media (min-width: 300px) and (max-width: 375px) {
  .categories-page p {
    font-size: 15px;
  }
}

@media (min-width: 650px) and (max-width: 1000px) {
  .categories-section {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1001px) {
  .categories-section {
    grid-template-columns: repeat(3, 1fr);
  }
}
