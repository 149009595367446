.product-card {
  width: 100%;
  max-width: 300px;
  height: 300px;
  background-color: var(--color-purple);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: var(--space-medium);
  transition: transform 0.3s; /* Animation */
}

.product-card:hover {
  transform: scale(1.05);
}

.product-card img {
  height: 65%;
  width: 100%;
  max-width: 100%;
  background-color: white;
  object-fit: contain;
  border-radius: 5px;
}

.product-card span {
  text-transform: uppercase;
}

.product-card .button-holder {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.product-card button {
  background-color: white;
  height: 50px;
  padding: 0 var(--space-big);
  border-radius: 3px;
}

@media (min-width: 300px) and (max-width: 375px) {
  .product-card {
    width: 260px;
  }
}
